import React from "react"
import Layout from '../../components/templates/Layout';
import {StaticQuery, graphql} from "gatsby";
import Header from "../../components/atoms/header/header";

import SnippetListWithData from "../../components/organisms/SnippetList/SnippetList.query";

const SnippetPage = () => (
    <StaticQuery query={graphql`
    {
        drupal {
            page:entityById(id: "55", langcode: EN, entityType: NODE) {
                ... on Drupal_NodePage {
                    title
                    body 
                    url {
                        ... on Drupal_EntityUrl {
                            metatags {
                                tag
                                attributes {
                                    key
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `}
     render={data => (
         <Layout
             lang={'en'}
             class={'snippets'}
             activeMenu={'snippets'}
             activeNid={55}
             metatags={data.drupal.page.url.metatags}
             title={data.drupal.page.title}
         >
             <Header
                 children={data.drupal.page.body}
                 page_title={data.drupal.page.title}
             />
             <SnippetListWithData />
         </Layout>
         )}
     />
);
export default SnippetPage;
